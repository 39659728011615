<template>
  <TextPage :html="privacyPolicy" />
</template>

<script>
import privacyPolicy from './privacy-policy.md';
import TextPage from '../text-page/text-page.vue';

export default {
  name: 'PrivacyPolicy',
  components: { TextPage },
  computed: {
    privacyPolicy() {
      return privacyPolicy;
    },
  },
};
</script>
