<template>
  <div class="privacy-policy">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
          <!-- eslint-disable-next-line -->
          <div class="editor" v-html="html" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextPage',
  props: {
    html: {
      type: String,
      required: true,
    },
  },
};
</script>
